@tailwind base;
@tailwind components;
@import url('https://use.typekit.net/dqi5aaa.css');

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.bg-staywell-main {
  background-color: #EF4555;
}

.bg-staywell-alt {
  background-color: #EF4555;
}

*::-moz-focus-inner {
    border: 0;
  }

*::-moz-focus { 
    border: 0; 
}

div::-moz-focus-outer {
  border: 0;
}

button::-moz-focus-inner {
  border: 0;
}

button::-moz-focus-outer {
  border: 0;
}


@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}



:focus {outline:none;}
::-moz-focus-inner {border:0;}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.upperShadow{
  --tw-shadow: 0 20px 25px 7px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0.2);
box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

  :root {
    --tooltip-text-color:#3A3D48;
    
    --tooltip-background-color:white;
      --tooltip-margin: 150px;
      --tooltip-arrow-size: 6px;
    }
    
    /* Wrapping */
    .Tooltip-Wrapper {
      display: inline-block;
      position: relative;
    }
    
    /* Absolute positioning */
    .Tooltip-Tip {
    
      position: absolute;
      left: 50%;
      transform: translateX(-52%);
      color: var(--tooltip-text-color);
      background: var(--tooltip-background-color);
      line-height: 1;
      z-index: 100;
    }
    
    /* CSS border triangles */
    .Tooltip-Tip::before {
      content: " ";
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: var(--tooltip-arrow-size);
      margin-left: calc(var(--tooltip-arrow-size) * -1);
    }
    
    /* Absolute positioning */
    .Tooltip-Tip.top {
      top: -140px;
    }
    /* CSS border triangles */
    .Tooltip-Tip.top::before {
      top: 100%;
      border-top-color: #718096;
  
    }
    
    /* Absolute positioning */
    .Tooltip-Tip.right {
      left: calc(100% + var(--tooltip-margin));
      top: 50%;
      transform: translateX(0) translateY(-50%);
    }
    /* CSS border triangles */
    .Tooltip-Tip.right::before {
      left: calc(var(--tooltip-arrow-size) * -1);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-right-color: var(--tooltip-background-color);
    }
    
    /* Absolute positioning */
    .Tooltip-Tip.bottom {
      bottom: calc(var(--tooltip-margin) * -1);
    }
    /* CSS border triangles */
    .Tooltip-Tip.bottom::before {
      bottom: 100%;
      border-bottom-color: var(--tooltip-background-color);
    }
    
    /* Absolute positioning */
    .Tooltip-Tip.left {
      left: auto;
      right: calc(100% + var(--tooltip-margin));
      top: 50%;
      transform: translateX(0) translateY(-50%);
    }
    /* CSS border triangles */
    .Tooltip-Tip.left::before {
      left: auto;
      right: calc(var(--tooltip-arrow-size) * -2);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-left-color: var(--tooltip-background-color);
    }

    .tooltip-top::after {
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid black;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

  
  
  .arrow-top:before, .arrow-top:after {
      content: "";
      position: absolute;
      left: 10px;
      top: -20px;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
      border-left: 10px solid transparent;
  }
  
  .arrow-top:after {
    border-bottom: 10px solid white;
    top: -19px;
  }

  .arrow-bottom:before, .arrow-bottom:after {
    content: "";
    position: absolute;
    left: 15px;
    bottom: -20px;
    border-top: 10px solid white;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
}

.arrow-bottom:after {
  border-top: 10px solid white;
  bottom: -19px;
}

    .tooltip-bottom {
      top: 100%;
      left: 50%;
      padding-top: 9px;
      transform: translateX(-50%);
      
   
    }

    .tooltip-bottom::after {
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid  black;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
.scroll-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.bg-f9{
  /* background-color: #F2F5FA ; */
  /* background-color: #f4f6fa ; */
  background-color: #f9fbfc ;

  
}







    
  
    .appear{
      animation:250ms appear;
      scale:1;
    }
  
    @keyframes appear {
      0% {
        opacity: 0;
        transform: scale(0.4);
        
        
        
      }
    
      70%{
        opacity: 0.7;
        transform: scale(1.05);
        
        
      }
    
      100% {
        opacity: 1;
        transform: scale(1);
        
        
        
      }
    }


    .slide-in-blurred-top {
      -webkit-animation: slide-in-blurred-top 0.4s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
              animation: slide-in-blurred-top 0.4s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    }


@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(500px) scaleY(2.5) scaleX(0.2);
            transform: translateY(500px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}


.scale-in-ver-top {
	-webkit-animation: scale-in-ver-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-ver-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;

  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
            -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

.fade-in {
	-webkit-animation: fade-in 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-bg {
	-webkit-animation: fade-bg 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-bg 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes fade-bg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes fade-bg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}




@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }
}
@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
  }
  100% {
    -webkit-transform: perspective(400px);
    -ms-transform: perspective(400px);
    transform: perspective(400px)
  }
}
.flipInX {
  -webkit-backface-visibility: visible!important;
  -ms-backface-visibility: visible!important;
  backface-visibility: visible!important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}




@-webkit-keyframes bounceInUp {
  0%, 100%, 60%, 75%, 90% {
    -webkit-transition-timing-function: cubic-bezier(0.215, .61, .355, 1);
    transition-timing-function: cubic-bezier(0.215, .61, .355, 1)
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0)
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0)
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}
@keyframes bounceInUp {
  0%, 100%, 60%, 75%, 90% {
    -webkit-transition-timing-function: cubic-bezier(0.215, .61, .355, 1);
    transition-timing-function: cubic-bezier(0.215, .61, .355, 1)
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    -ms-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0)
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0)
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}



/*base code*/



/*the animation definition*/
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


.pullUp {
  -webkit-animation-name: pullUp;
  animation-name: pullUp;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

@keyframes pullUp {
  0% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0); }
  30% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }
.pullDown {
  -webkit-animation-name: pullDown;
  animation-name: pullDown;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }


  .swoopInBottom {
    -webkit-animation-name: swoopInBottom;
    animation-name: swoopInBottom;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s; }
  
  @keyframes swoopInBottom {
    0% {
      opacity: 0;
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: scaleY(1.5) translate3d(0, 250px, 0);
      transform: scaleY(1.5) translate3d(0, 250px, 0); }
    40% {
      opacity: 1;
      -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      -webkit-transform: scaleY(1.2) translate3d(0, 0, 0);
      transform: scaleY(1.2) translate3d(0, 0, 0); }
    65% {
      -webkit-transform: scaleY(1) translate3d(0, -20px, 0);
      transform: scaleY(1) translate3d(0, -20px, 0); }
    100% {
      -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      -webkit-transform: scaleY(1) translate3d(0, 0, 0);
      transform: scaleY(1) translate3d(0, 0, 0); } }
  .swoopOutLeft {
    -webkit-animation-name: swoopOutLeft;
    animation-name: swoopOutLeft;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s; }


    .SlideLeft {
      -webkit-animation-name: SlideLeft;
      animation-name: SlideLeft;
      -webkit-animation-duration: 0.4s;
      animation-duration: 0.4s; 
      animation-fill-mode: forwards;
    }

    @keyframes SlideLeft {
      0% {
        margin-left: 120%;
      }

      100%{
        margin-left:0;
      }
    }


    .SlideRight {
      -webkit-animation-name: SlideRight;
      animation-name: SlideRight;
      -webkit-animation-duration: 0.5s;
      animation-duration: 0.5s; 
      animation-fill-mode: forwards;
    }

    @keyframes SlideRight {
      0% {
        margin-left: 0 ;
        opacity : 1
      }

      100%{
        margin-left:120%;
        opacity: 0;
      }
    }


    .shake {
      -webkit-animation-name: shake;
      animation-name: shake;
      -webkit-animation: shake 0.8s ease-in-out 2s both;
              animation: shake 0.8s ease-in-out 2s both;
    }


@keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}

.errorAni{
  animation: shake 0.6s ease-in-out 0.3s, SlideLeft 0.4s
}


.h1{
  font-family:"SF Pro";
  font-size: 32px;
}

.h2{
  font-family: "europa";
  font-size: 20px;
}

.h3{
  font-family: "SF Pro";
  font-size: 20px;
  color: #333333;
}

.p{
  font-family: "europa";
  font-size: 16px;
  color: #2B3E50;
}

.line{
  max-width: 100%;
  min-height: 0.25rem;
  margin-top: 1rem;
  background-color: #2DC385;
}


.box{
  max-width: 20%;
  min-height: 2.25rem;
  border-radius: 0.125rem;
  border-width: 1px;
  border-color: rgb(190 190 190);

}

.formBackground{
  background-color: rgb(246 248 250);
}

.formCard{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 24px;

  position: absolute;
  background-color: #FFFFFF;
  border-radius: 24px;
  width: 760px;
  height: 562px;
}

.spacerY{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 48px;
  height: 24px;
}

.spacerX{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 16px;
  height: 32px;
  }

.rowStart{
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  width: 712px;
  height: 56px;
}

.rowEnd{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  width: 712px;
  height: 36px;

}

.checkboxLayout{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 100%;

  flex: none;
  flex-grow: 0;
}

.checkbox{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 9px 9px 0px;
  gap: 10px;

  width: 24px;
  height: 24px;

  border-radius: 4px;
  border-width: 2px;
}

.incrementButtn{
  background-color: rgb(255 255 255);
  width: 24px;
  height: 24px;
  border-radius: 4px;

}

.yellowCleaning{
  background-color: rgb(245 186 84)
}

.maroonCleaning{
  background-color: rgb(99, 2, 17)
}

.greenCleaning{
  background-color: rgb(52, 184, 43)
}

.purpleCleaning{
  background-color: rgb(67, 38, 156)
}

.lightPurpleCleaning{
  background-color: rgb(198, 191, 238)
}

.brownCleaning{
  background-color: rgb(182, 174, 148)
}

.grayCleaning{
  background-color: rgb(108, 104, 103)
}

.salmonCleaning{
  background-color: rgb(218, 164, 137)
}

.blueCleaning{
  background-color: rgb(17, 78, 168)
}

@tailwind utilities;
